<template>
  <div v-if="dialog && users">
    <v-dialog
      :value="dialog"
      max-width="60%"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <div class="modal-header">
          <h5 class="modal-title" id="formModal">
            {{
              this.reportUrl == "master"
                ? "List Senior Statement"
                : this.reportUrl == "agent"
                ? "List Master Statement"
                : this.reportUrl == "player"
                ? "List Agent Statement"
                : this.reportUrl == "playerDetail"
                ? "List Player Statement"
                : "Loading ..."
            }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>
        <v-card-text>
          <br />
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-search"></i>
              </div>
            </div>
            <input v-model="v_search" type="text" class="form-control mr-1" />
            <button
              class="btn btn-primary"
              color="green darken-1"
              depressed
              @click="search"
            >
              SEARCH
            </button>
          </div>
          <br />
          <v-data-table
            style="font-family: 'khmer mef1'; text-align: center"
            class="elevation-1"
            item-key="_id._id"
            :headers="headers"
            :footer-props="footerProps"
            :items="users ? users.object : []"
            :server-items-length="users ? users.objectCount.count : null"
            :sort-desc.sync="dataRequest.descendingFormat"
            :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page"
            :items-per-page.sync="dataRequest.rowsPerPage"
            @update:page="$emit('getData1')"
            @update:items-per-page="$emit('getData1')"
            @update:sort-desc="$emit('getData1')"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>
                  <v-icon
                    v-if="
                      user.role_name == 'MASTER-D' ||
                      user.role_name == 'SUB-MASTER-D'
                    "
                    color="green"
                    small
                    class=""
                    @click="getUserChild5(item)"
                    >mdi-eye
                  </v-icon>
                  <v-icon
                    v-if="
                      user.role_name !== 'MASTER-D' &&
                      user.role_name !== 'SUB-MASTER-D'
                    "
                    color="green"
                    small
                    class=""
                    @click="getUserChild2(item)"
                    >{{ reportUrl ? "mdi-eye" : "mdi-eye-off" }}
                  </v-icon>
                </td>
                <td>{{ users.object.indexOf(item) + 1 }}</td>
                <td>{{ item._id.app_id }}</td>
                <td>{{ item._id.user_name }}</td>
                <td>{{ currencyFormat(item._id.balance) }}</td>
                <td class="amount-bet-color">
                  {{ currencyFormat(item.amount) }}
                </td>
                <td :class="mgReport(user._id,p_com_id,item._id._id,item.amount_win) < 0 ? 'lose-color' : 'win-color'">
                  <!-- {{ currencyFormat(item.amount_win) }} -->
                  {{ mgReport(user._id,p_com_id,item._id._id,item.amount_win) }}
                </td>
                <!-- <td class="lose-color">
                  {{ currencyFormat(item.amount_lose) }}
                </td> -->
              </tr>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                v-if="
                  user.role_name == 'MASTER-D' ||
                  user.role_name == 'SUB-MASTER-D'
                "
                color="green"
                small
                class=""
                @click="getUserChild5(item)"
                >mdi-eye
              </v-icon>
              <v-icon
                v-if="
                  user.role_name != 'MASTER-D' &&
                  user.role_name != 'SUB-MASTER-D'
                "
                color="green"
                small
                class=""
                @click="getUserChild2(item)"
                >{{ reportUrl ? "mdi-eye" : "mdi-eye-off" }}
              </v-icon>
            </template>
            <template slot="body.append">
              <tr style="background-color: #faffe5; font-weight: bold;">
              <td :colspan="4" class="text-xs-center pl-4 py-2">
                {{ $t("title.total") }}
              </td>
              <td class="text-xs-center total-style pl-4 py-2">
                {{
                  users.objectCount.balance
                    ? currencyFormat(users.objectCount.balance)
                    : 0
                }}
              </td>
              <td class="total-style">
                {{
                  users.objectCount.amount
                    ? currencyFormat(users.objectCount.amount)
                    : 0
                }}
              </td>
              <td
                class="total-style font-weight-bold"
                :style="
                  total_win_lose < 0
                    ? 'color:#BA2D4A'
                    : 'color:#39afd6'
                "
              >
                {{
                  users.objectCount.amount_win
                    ? mgReport(user._id,p_com_id,"",users.objectCount.amount_win)
                    : 0
                }}
              </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <statement-list2
      v-if="user.role_name != 'MASTER-D' && user.role_name !== 'SUB-MASTER-D'"
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild2"
      :data-request="data2"
      :user="user"
      :p_sen_id="p_sen_id"
      v-on:getData2="getUserChild2(item)"
      @onSearch="onSearch"
      :date="date"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />
    <statement-list5
      v-if="user.role_name == 'MASTER-D' || user.role_name == 'SUB-MASTER-D'"
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild5"
      :data-request="data5"
      v-on:getData5="getUserChild5(item)"
      :date="date"
      @onSearch="onSearch"
      :user="user"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />
  </div>
</template>

<script>
import headers from "../../_api/columns";
import StatementList2 from "./Dialog2";
import StatementList5 from "./Dialog5";
import moment from "moment";
import Vue from "vue";
export default {
  components: { StatementList2, StatementList5 },
  props: [
    "darkmode",
    "dialog",
    "users",
    "dataRequest",
    "user",
    "date",
    "loadingScreen1",
    "p_com_id",
    "totalExceptSenior1",
    "totalExceptSenior2"
  ],
  data: () => {
    return {
      dialogListUser: false,
      headers: headers,
      userChild2: [],
      userChild5: [],
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      item: null,
      data2: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id._id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: [
          "_id.user_name",
          "_id.balance",
          "amount",
          "amount_win",
          "_id.app_id",
        ],
        search: "",
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
      },
      data5: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id._id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: [
          "sn_number.name",
          "sn_number.desc",
          "sn_number.payout",
          "date",
          "fight_no",
          "is_win",
          "amount",
          "amount_win",
          "cast",
        ],
        search: "",
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
      },
      v_startDate: false,
      v_endDate: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      v_search: "",
      params: "",
      p_sen_id: null,
      total_win_lose: 0
    };
  },
  computed: {
    reportUrl: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return "master";
        else if (
          this.user.role_name == "COMPANY" ||
          this.user.role_name == "SUB-COMPANY"
        )
          return "agent";
        else if (
          this.user.role_name == "SENIOR-D" ||
          this.user.role_name == "SUB-SENIOR-D"
        )
          return "player";
        else if (
          this.user.role_name == "MASTER-D" ||
          this.user.role_name == "SUB-MASTER-D"
        )
          return "playerDetail";
        // else if(this.user.role_name == "AGENT") return "getViewPlayerDetail"
        else return null;
      } else return null;
    },
  },
  user: function () {
    // Emit this information to the parents component
  },
  methods: {
    mgReport(mg_id,com_id,sen_id,win_lose_amount){
      let new_win_lose;
      let env_mg = process.env.VUE_APP_MG_ID;
      let env_com1 = process.env.VUE_APP_COM_ID1;
      let env_com2 = process.env.VUE_APP_COM_ID2;
      let env_sen1 = process.env.VUE_APP_SENIOR_ID1;
      let env_sen2 = process.env.VUE_APP_SENIOR_ID2;
      let env_percentage = process.env.VUE_APP_PERCENTAGE;
      let env_stDate = process.env.VUE_APP_START_DATE;
      let arr_mg = env_mg ? JSON.parse(env_mg) : [];
      let arr_sen1 = env_sen1? JSON.parse(env_sen1): [];
      let arr_sen2 = env_sen2? JSON.parse(env_sen2) : [];
      let all_sen = arr_sen1.concat(arr_sen2);
      let percentage = env_percentage? parseInt(env_percentage) : 0;
      let env_st_date = moment(env_stDate).format("yyyy-MM-DD");
      let st_date = moment(this.date.startDate).format("yyyy-MM-DD");
      if(st_date < env_st_date){
        new_win_lose = win_lose_amount;
        return this.currencyFormat(new_win_lose);
      }
      if(percentage > 0){
        if(!arr_mg.includes(mg_id)){
          new_win_lose = win_lose_amount;
        }else{
          if(sen_id == ""){
            if(com_id == env_com1){
              new_win_lose = (win_lose_amount - this.totalExceptSenior1) + this.totalExceptSenior1*(percentage/100);
            }else if(com_id == env_com2){
              new_win_lose = (win_lose_amount - this.totalExceptSenior2) + this.totalExceptSenior2*(percentage/100);
            }else{
              new_win_lose = win_lose_amount;
            }
            this.total_win_lose = new_win_lose;
          }else if(all_sen.includes(sen_id)){
            new_win_lose = (percentage/100) * win_lose_amount;
          }else{
            new_win_lose = win_lose_amount;
          }
        }
      }else{
        new_win_lose = win_lose_amount;
      }
      
      return this.currencyFormat(new_win_lose);
    },
    getUserChild2(item, search = "") {
      if (search) this.data2.search = search;
      else this.data2.search = "";
      if (this.date) {
        this.data2.startDate = this.date.startDate;
        this.data2.endDate = this.date.endDate;
      }
      this.item = item;
      this.p_sen_id = item._id._id;
      this.data2.sortByFormat.length < 1
        ? (this.data2.sortBy = "_id")
        : (this.data2.sortBy = this.data2.sortByFormat[0]);
      this.data2.descendingFormat.length > 0
        ? (this.data2.descending = this.data2.descendingFormat[0])
        : (this.data2.descending = true);
      Vue.$cookies.set("currency-type", 1, 0);
      this.$request
        .post({
          url: "statementChannel/" + this.reportUrl + "/" + this.item._id._id,
          data: this.data2,
        })
        .then((res) => {
          if (res.data.code) {
            this.userChild2 = res.data.data;
            this.dialogListUser = true;
          }
        });
    },
    getUserChild5(item, search = "") {
      if (search) this.data5.search = search;
      else this.data5.search = "";
      if (this.date) {
        this.data5.startDate = this.date.startDate;
        this.data5.endDate = this.date.endDate;
      }
      this.item = item;
      this.data5.sortByFormat.length < 1
        ? (this.data5.sortBy = "_id")
        : (this.data5.sortBy = this.data5.sortByFormat[0]);
      this.data5.descendingFormat.length > 0
        ? (this.data5.descending = this.data5.descendingFormat[0])
        : (this.data5.descending = true);
      Vue.$cookies.set("currency-type", 1, 0);
      this.$request
        .post({
          url: "statementChannel/" + this.reportUrl + "/" + this.item._id._id,
          data: this.data5,
        })
        .then((res) => {
          if (res.data.code) {
            this.dialogListUser = true;

            this.userChild5 = res.data.data;
          }
        });
    },
    onSearch(search) {
      if (
        this.user.role_name == "MASTER-D" ||
        this.user.role_name == "SUB-MASTER-D"
      )
        this.getUserChild5(this.item, search);
      else this.getUserChild2(this.item, search);
    },
    search() {
      // if(this.user.role_name=="MASTER") this.$emit('getData5', this.v_search)
      this.$emit("getData1", this.v_search);
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
    },
  },
};
</script>

<style scoped>
.btn-custom {
  position: relative;
  top: 10px;
}
.amount-bet-color {
  color: goldenrod !important;
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
</style>
