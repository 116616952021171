<template>
  <div v-if="dialog && users">
    <v-dialog
      :value="dialog"
      max-width="60%"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <div class="modal-header">
          <h5 class="modal-title" id="formModal">Detail Player Statement</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>
        <v-card-text>
          <br />
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-search"></i>
              </div>
            </div>
            <input v-model="v_search" type="text" class="form-control mr-1" />
            <button
              class="btn btn-primary"
              color="green darken-1"
              depressed
              @click="search"
            >
              SEARCH
            </button>
          </div>
          <br />
          <v-data-table
            style="font-family: 'khmer mef1'; text-align: center"
            class=" table table-striped"
            item-key="_id._id"
            :headers="headers"
            :footer-props="footerProps"
            :items="users ? users.object : []"
            :server-items-length="
              users ? parseInt(users.objectCount.count) : null
            "
            :sort-desc.sync="dataRequest.descendingFormat"
            :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page"
            :items-per-page.sync="dataRequest.rowsPerPage"
            @update:page="updatePage"
            @update:items-per-page="updatePage"
            @update:sort-desc="updatePage"
            :loading="getLoading"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <!-- <td>{{ users.object.indexOf(item) + 1 }}</td> -->
                <td>{{ item.app_id }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.fight_no }}</td>
                <td :class="item.type_of_betting.color">
                  {{changeType(item.type_of_betting.desc)}}
                </td>
                <td>
                  <span :class="item.result1.color" v-if="item.result1">
                      {{ changeType(item.result1.name) }} {{ item.result1.number_label }} 
                    </span>
                    <span :class="item.result2.color" v-if="item.result2">
                      | {{ item.result2.name }} {{ item.result2.number_label }} 
                    </span>
                    <span :class="item.result3.color" v-if="item.result3">
                     | {{ item.result3.name }} {{ item.result3.number_label }} 
                    </span>
                    <span :class="item.result4.color" v-if="item.result4">
                     | {{ item.result4.name }} {{ item.result4.number_label }} 
                    </span>
                    <span :class="item.result5.color" v-if="item.result5">
                     | {{ item.result5.name }} {{ item.result5.number_label }}
                    </span>
                  <!-- <span v-else>--</span> -->
                </td>
                <td class="amount-bet-color">
                  {{ currencyFormat(item.amount) }}
                </td>
                <td
                  class="font-weight-bold"
                  :style="
                    item.amount_win < 0 ? 'color:#BA2D4A; ' : 'color:#39afd6'
                  "
                >
                  {{ item.amount_win ? currencyFormat(item.amount_win):0 }}
                </td>
                <td class="font-weight-bold">
                  {{ item.cast ? currencyFormat(item.cast) : 0 }}
                </td>
              </tr>
            </template>
            <template slot="body.append">
              <tr style="background-color: #faffe5; font-weight: bold;">
              <td :colspan="5" class="text-xs-center pl-4 py-2">
                {{ $t("title.total") }}
              </td>
              <td class="total-style">
                {{
                  users.objectCount.amount
                    ? currencyFormat(users.objectCount.amount)
                    : 0
                }}
              </td>
              <!-- <td></td> -->
             
              <td
                class="total-style font-weight-bold"
                :style="
                  users.objectCount.amount_win < 0
                    ? 'color:#BA2D4A'
                    : 'color:#39afd6'
                "
              >
                {{
                  users.objectCount.amount_win
                    ? mgReport(user._id,p_sen_id,users.objectCount.amount_win)
                    : 0
                }}
              </td>
              <td></td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["darkmode", "dialog", "users", "dataRequest", "date", "user", "p_sen_id"],
  data: () => {
    return {
      dialogListUser: false,
      headers: [
        // {
        //   text: "No",
        //   align: "center",
        //   value: "no",
        // },
        { text: "App ID", value: "app_id", align: "center" },
        { text: "Date", value: "date", align: "center" },
        { text: "Fight No", value: "fight_no", align: "center" },
        { text: "Betting", value: "type_of_betting.name", align: "center" },
        { text: "Result", value: "result1.name", align: "center" },
        { text: "Total Amount", value: "amount", align: "center" },
        { text: "Amount Win/Lose", value: "amount_win", align: "center" },
        { text: "Cast", value: "cast", align: "center" },
      ],
      userChild: [],
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      v_startDate: false,
      v_endDate: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      v_search: "",
      getLoading: false,
    };
  },
  computed: {
    reportUrl: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return "playerDetail";
        else return null;
      } else return null;
    },
  },
  methods: {
    mgReport(mg_id,sen_id,win_lose_amount){
      let new_win_lose;
      let env_mg = process.env.VUE_APP_MG_ID;
      let env_sen1 = process.env.VUE_APP_SENIOR_ID1;
      let env_sen2 = process.env.VUE_APP_SENIOR_ID2;
      let env_percentage = process.env.VUE_APP_PERCENTAGE;
      let env_stDate = process.env.VUE_APP_START_DATE;
      let arr_mg = env_mg ? JSON.parse(env_mg) : [];
      let arr_sen1 = env_sen1? JSON.parse(env_sen1): [];
      let arr_sen2 = env_sen2? JSON.parse(env_sen2) : [];
      let all_sen = arr_sen1.concat(arr_sen2);
      let percentage = env_percentage? parseInt(env_percentage) : 0;
      let env_st_date = moment(env_stDate).format("yyyy-MM-DD");
      let st_date = moment(this.date.startDate).format("yyyy-MM-DD");
      if(st_date < env_st_date){
        new_win_lose = win_lose_amount;
        return this.currencyFormat(new_win_lose);
      }
      if(percentage > 0){
        if(!arr_mg.includes(mg_id)){
          new_win_lose = win_lose_amount;
        }else{
          if(all_sen.includes(sen_id)){
            new_win_lose = (percentage/100) * win_lose_amount;
          }else{
            new_win_lose = win_lose_amount;
          }
        }
      }else{
        new_win_lose = win_lose_amount;
      }
      return this.currencyFormat(new_win_lose);
    },
    search() {
      if (
        this.user.role_name == "AGENT-D" &&
        this.user.role_name == "SUB-AGENT-D"
      )
        this.$emit("getData5", this.v_search);
      else this.$emit("onSearch", this.v_search);
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString('en')
    },
    updatePage() {
      this.getLoading = true;
      this.$emit("getData5");
      this.getLoading = false;
    },
    changeType(type){
      let channelType = parseInt(this.$cookies.get("channel-type"));
      let bet_type = '';
      if(channelType == 15){
        if(type == 'WALA'){
          bet_type = 'PLAYER'
        }else if(type == 'MERON'){
          bet_type = 'BANKER'
        }else{
          bet_type = type;
        }
      }else if(channelType == 16){
        if(type == 'WALA'){
          bet_type = 'BLACK'
        }else if(type == 'MERON'){
          bet_type = 'RED'
        }else{
          bet_type = type;
        }
      }else{
        bet_type = type;
      }
      return bet_type;
    },
  },
};
</script>

<style scoped>
.amount-bet-color {
  color: goldenrod !important;
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
.cir-red {
  color: #ba2d4a;
  font-weight: bold;
}
.cir-blue {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.cir-tie {
  color: grey;
}
.cir-cancel {
  color: grey;
}
</style>
